import type { SVGProps } from 'react';

export default function SkFlag(props: SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            preserveAspectRatio="xMidYMid slice"
            {...props}>
            <circle cx="256" cy="256" fill="#f0f0f0" r="256" />
            <path
                d="m256 512c141.384 0 256-114.616 256-256 0-31.314-5.632-61.311-15.923-89.043h-480.154c-10.29 27.732-15.923 57.729-15.923 89.043 0 141.384 114.616 256 256 256z"
                fill="#0052b4"
            />
            <path
                d="m256 512c110.071 0 203.906-69.472 240.077-166.957h-480.154c36.171 97.485 130.006 166.957 240.077 166.957z"
                fill="#d80027"
            />
            <path
                d="m66.198 144.696v127.706c0 72.644 94.901 94.903 94.901 94.903s94.9-22.259 94.9-94.903v-127.706z"
                fill="#f0f0f0"
            />
            <path
                d="m88.459 144.696v127.706c0 8.528 1.894 16.582 5.646 24.133h133.987c3.752-7.55 5.646-15.605 5.646-24.133v-127.706z"
                fill="#d80027"
            />
            <path
                d="m205.62 233.739h-33.39v-22.261h22.261v-22.261h-22.261v-22.26h-22.261v22.26h-22.26v22.261h22.26v22.261h-33.392v22.261h33.392v22.261h22.261v-22.261h33.39z"
                fill="#f0f0f0"
            />
            <path
                d="m124.471 327.61c14.345 9.015 29.083 14.249 36.628 16.577 7.545-2.327 22.283-7.562 36.628-16.577 14.466-9.092 24.607-19.49 30.365-31.075-6.351-4.493-14.1-7.143-22.471-7.143-3.048 0-6.009.361-8.856 1.024-6.033-13.708-19.728-23.285-35.665-23.285s-29.633 9.577-35.665 23.285c-2.847-.663-5.809-1.024-8.856-1.024-8.371 0-16.12 2.65-22.471 7.143 5.755 11.584 15.896 21.982 30.363 31.075z"
                fill="#0052b4"
            />
        </svg>
    );
}
