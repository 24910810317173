import type { ComponentProps } from 'react';

import { MarketEnum } from '@/config/markets/const';

import AtFlag from './AtFlag';
import BeFlag from './BeFlag';
import CzFlag from './CzFlag';
import DeFlag from './DeFlag';
import DkFlag from './DkFlag';
import EeFlag from './EeFlag';
import EsFlag from './EsFlag';
import FiFlag from './FiFlag';
import FrFlag from './FrFlag';
import GrFlag from './GrFlag';
import HrFlag from './HrFlag';
import HuFlag from './HuFlag';
import ItFlag from './ItFlag';
import LtFlag from './LtFlag';
import LvFlag from './LvFlag';
import NlFlag from './NlFlag';
import NoFlag from './NoFlag';
import PlFlag from './PlFlag';
import PtFlag from './PtFlag';
import RoFlag from './RoFlag';
import SeFlag from './SeFlag';
import SiFlag from './SiFlag';
import SkFlag from './SkFlag';

type FlagProps = ComponentProps<'svg'> & {
    code: MarketEnum;
};

export default function Flag({ code, ...props }: FlagProps) {
    switch (code) {
        case MarketEnum.at:
            return <AtFlag {...props} />;
        case MarketEnum.be:
            return <BeFlag {...props} />;
        case MarketEnum.cz:
            return <CzFlag {...props} />;
        case MarketEnum.de:
            return <DeFlag {...props} />;
        case MarketEnum.dk:
            return <DkFlag {...props} />;
        case MarketEnum.ee:
            return <EeFlag {...props} />;
        case MarketEnum.es:
            return <EsFlag {...props} />;
        case MarketEnum.fi:
            return <FiFlag {...props} />;
        case MarketEnum.fr:
            return <FrFlag {...props} />;
        case MarketEnum.gr:
            return <GrFlag {...props} />;
        case MarketEnum.hr:
            return <HrFlag {...props} />;
        case MarketEnum.hu:
            return <HuFlag {...props} />;
        case MarketEnum.it:
            return <ItFlag {...props} />;
        case MarketEnum.lt:
            return <LtFlag {...props} />;
        case MarketEnum.lv:
            return <LvFlag {...props} />;
        case MarketEnum.nl:
            return <NlFlag {...props} />;
        case MarketEnum.no:
            return <NoFlag {...props} />;
        case MarketEnum.pl:
            return <PlFlag {...props} />;
        case MarketEnum.pt:
            return <PtFlag {...props} />;
        case MarketEnum.ro:
            return <RoFlag {...props} />;
        case MarketEnum.se:
            return <SeFlag {...props} />;
        case MarketEnum.si:
            return <SiFlag {...props} />;
        case MarketEnum.sk:
            return <SkFlag {...props} />;
        default:
            return null;
    }
}
