import type { SVGProps } from 'react';

export default function NoFlag(props: SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            preserveAspectRatio="xMidYMid slice"
            {...props}>
            <circle cx="256" cy="256" fill="#f0f0f0" r="256" />
            <g fill="#d80027">
                <path d="m8.819 322.783c14.812 54.959 47.457 102.593 91.355 136.322v-136.322z" />
                <path d="m233.739 511.026c7.338.632 14.759.974 22.261.974 118.279 0 217.805-80.221 247.181-189.217h-269.442z" />
                <path d="m503.181 189.217c-29.376-108.996-128.902-189.217-247.181-189.217-7.502 0-14.923.342-22.261.974v188.243z" />
                <path d="m100.174 52.895c-43.898 33.73-76.543 81.363-91.355 136.322h91.355z" />
            </g>
            <path
                d="m509.833 222.609h-309.484-.001v-216.524c-23.658 5.246-46.087 13.749-66.783 25.042v191.48.001h-131.398c-1.425 10.931-2.167 22.075-2.167 33.392s.742 22.461 2.167 33.391h131.397.001v191.481c20.696 11.292 43.125 19.797 66.783 25.042v-216.52-.002h309.485c1.423-10.931 2.167-22.075 2.167-33.392s-.744-22.461-2.167-33.391z"
                fill="#0052b4"
            />
        </svg>
    );
}
