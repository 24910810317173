import type { SVGProps } from 'react';

export default function SeFlag(props: SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            preserveAspectRatio="xMidYMid slice"
            {...props}>
            <circle cx="256" cy="256" fill="#ffda44" r="256" />
            <g fill="#0052b4">
                <path d="m200.349 222.609h309.484c-16.363-125.607-123.766-222.609-253.833-222.609-19.114 0-37.733 2.113-55.652 6.085v216.524z" />
                <path d="m133.565 222.608v-191.481c-70.294 38.354-120.615 108.705-131.398 191.482h131.398z" />
                <path d="m133.564 289.391h-131.397c10.783 82.777 61.104 153.128 131.398 191.481z" />
                <path d="m200.348 289.392v216.523c17.919 3.972 36.538 6.085 55.652 6.085 130.067 0 237.47-97.002 253.833-222.609h-309.485z" />
            </g>
        </svg>
    );
}
