import Flag from '@components/Flag';
import { ArrowRightIcon } from '@ngg/icons';
import type { LinkProps } from 'next/link';
import Link from 'next/link';

import type { MarketConfiguration } from '@/config/markets/const';

type RichTextLinkProps = Omit<LinkProps, 'href'> & {
    href: string;
    market: MarketConfiguration;
};

const RichTextMarketLink = ({ href, market, ...props }: RichTextLinkProps) => {
    return (
        <Link
            href={href}
            className="group flex items-center no-underline space-x-2"
            {...props}>
            <Flag
                code={market.code}
                className="h-10 w-10 flex-none rounded-full"
            />
            <span className="flex flex-1 flex-col group-hover:underline">
                <span className="text-lg font-bold">{market.label}</span>
                <span className="text-xxs">({market.language.label})</span>
            </span>
            <ArrowRightIcon className="flex-none text-xl" />
        </Link>
    );
};

RichTextMarketLink.displayName = 'RichTextMarketLink';

export default RichTextMarketLink;
