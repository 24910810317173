import Link from '@app/_components/Link';
import { getPageById } from '@server/requests/contentful/getPageById';
import type { PropsWithChildren } from 'react';

import type { MarketConfiguration } from '@/config/markets/const';

export default async function RichTextAsyncLink({
    id,
    children,
    marketConfig,
}: PropsWithChildren<{
    id?: string;
    marketConfig: MarketConfiguration;
}>) {
    if (!id) {
        return null;
    }
    const page = await getPageById({
        id,
        locale: marketConfig.contentfulLocale,
    });
    return (
        <Link locale={marketConfig.locale} href={page?.slug}>
            {children}
        </Link>
    );
}
