import type { SVGProps } from 'react';

export default function LvFlag(props: SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            preserveAspectRatio="xMidYMid slice"
            {...props}>
            <circle cx="256" cy="256" fill="#f0f0f0" r="256" />
            <g fill="#a2001d">
                <path d="m256 0c-118.279 0-217.805 80.221-247.181 189.217h494.363c-29.377-108.996-128.903-189.217-247.182-189.217z" />
                <path d="m256 512c118.279 0 217.805-80.221 247.181-189.217h-494.362c29.376 108.995 128.902 189.217 247.181 189.217z" />
            </g>
        </svg>
    );
}
