import type { SVGProps } from 'react';

export default function HrFlag(props: SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            preserveAspectRatio="xMidYMid slice"
            {...props}>
            <path
                d="m512 256c0-31.314-5.633-61.311-15.923-89.043l-240.077-11.131-240.077 11.13c-10.29 27.733-15.923 57.73-15.923 89.044s5.633 61.311 15.923 89.043l240.077 11.131 240.077-11.13c10.29-27.733 15.923-57.73 15.923-89.044z"
                fill="#f0f0f0"
            />
            <path
                d="m256 512c110.071 0 203.906-69.472 240.077-166.957h-480.154c36.171 97.485 130.006 166.957 240.077 166.957z"
                fill="#0052b4"
            />
            <path
                d="m15.923 166.957h480.155c-36.172-97.485-130.007-166.957-240.078-166.957s-203.906 69.472-240.077 166.957z"
                fill="#d80027"
            />
            <g fill="#338af3">
                <path d="m322.783 178.088h-44.522l7.421-55.653 29.68-22.261 29.681 22.261v44.522z" />
                <path d="m189.217 178.088h44.522l-7.421-55.653-29.681-22.261-29.68 22.261v44.522z" />
            </g>
            <path
                d="m285.682 178.088h-59.364v-55.653l29.682-22.261 29.682 22.261z"
                fill="#0052b4"
            />
            <path
                d="m166.957 166.958v122.434c0 29.153 14.082 55.079 35.802 71.332l15.583-3.899 19.664 19.782c5.815 1.198 11.832 1.829 17.995 1.829 6.13 0 12.117-.623 17.901-1.809l22.447-18.69 12.892 2.751c21.711-16.252 35.803-42.151 35.803-71.296v-122.434z"
                fill="#f0f0f0"
            />
            <g fill="#d80027">
                <path d="m166.957 166.957h35.617v35.617h-35.617z" />
                <path d="m238.191 166.957h35.617v35.617h-35.617z" />
                <path d="m309.426 166.957h35.617v35.617h-35.617z" />
                <path d="m202.574 202.574h35.617v35.617h-35.617z" />
                <path d="m273.809 202.574h35.617v35.617h-35.617z" />
                <path d="m166.957 238.18h35.617v35.617h-35.617z" />
                <path d="m202.574 273.798h35.617v35.617h-35.617z" />
                <path d="m238.191 238.18h35.617v35.617h-35.617z" />
                <path d="m309.426 238.18h35.617v35.617h-35.617z" />
                <path d="m273.809 273.798h35.617v35.617h-35.617z" />
                <path d="m238.191 309.415h35.617v35.617h-35.617z" />
                <path d="m202.574 309.418h-33.319c3.056 13.24 9.064 25.355 17.302 35.617h16.017z" />
                <path d="m309.426 345.036h16.016c8.24-10.262 14.246-22.378 17.302-35.617h-33.318z" />
                <path d="m202.574 345.036v15.541c10.359 7.795 22.465 13.384 35.617 16.066v-31.607z" />
                <path d="m273.809 345.036v31.607c13.153-2.68 25.258-8.271 35.617-16.066v-15.541z" />
            </g>
        </svg>
    );
}
