/* eslint-disable no-console */

// Some of these entries are client only.

import dynamic from 'next/dynamic';

import { camelCase } from '@/utils/common';

const Accordion = dynamic(() => import('@blocks/Accordion'));
const ContentfulImage = dynamic(
    () => import('@components/ContentfulImage/ContentfulImage'),
);
const Block = dynamic(() => import('@blocks/Block'));
const Countdown = dynamic(() =>
    import('@blocks/Countdown').then((mod) => mod.Countdown),
);
const Form = dynamic(() => import('@blocks/Form'));
const TextBlock = dynamic(() => import('@blocks/TextBlock'));
const ContentRow = dynamic(() => import('@blocks/ContentRow'));
const Splash = dynamic(() => import('@blocks/Splash'));
const Store = dynamic(() => import('@blocks/Store'));
const BrandBanner = dynamic(() => import('@blocks/BrandBanner'));
const HighlightRow = dynamic(() => import('@blocks/HighlightRow'));
const LinkList = dynamic(() => import('@blocks/LinkList'));
const NavigationLink = dynamic(() => import('@blocks/NavigationLink'));
const EditorialGallery = dynamic(() => import('@blocks/EditorialGallery'));

const DYNAMIC_COMPONENTS = {
    accordion: Accordion,
    block: Block,
    contentfulImage: ContentfulImage,
    countdown: Countdown,
    contentRow: ContentRow,
    form: Form,
    splash: Splash,
    store: Store,
    textBlock: TextBlock,
    brandBanner: BrandBanner,
    linkList: LinkList,
    highlightRow: HighlightRow,
    navigationLink: NavigationLink,
    editorialGallery: EditorialGallery,
} as { [key: string]: (props: any) => JSX.Element };

const Server = async ({
    __typename,
    ...props
}: any & { __typename: string }) => {
    let data;
    const Component = DYNAMIC_COMPONENTS[camelCase(__typename ?? '')];
    if (Component) return <Component {...props} data={data} />;
    console.group(
        console.warn(`Unable to render component ${__typename}`),
        console.warn(props),
    );
    return null;
};

const Client = ({ __typename, ...props }: any & { __typename: string }) => {
    let data;
    const Component = DYNAMIC_COMPONENTS[camelCase(__typename ?? '')];
    if (Component) return <Component client {...props} data={data} />;
    console.group(
        console.warn(`Unable to render component ${__typename}`),
        console.warn(props),
    );
    return null;
};

// The *@client* prop regulates if the component should be rendered client or server-side, per default the component will render server-side
export default function ClientFriendlyEntry({
    client = false,
    ...props
}: any & { client: boolean }) {
    if (client) return <Client {...props} />;

    return <Server {...props} />;
}
