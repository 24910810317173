import type { SVGProps } from 'react';

export default function CzFlag(props: SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            preserveAspectRatio="xMidYMid slice"
            {...props}>
            <circle cx="256" cy="256" fill="#f0f0f0" r="256" />
            <path
                d="m233.739 256s-158.609 181.055-158.759 181.019c46.326 46.327 110.327 74.981 181.02 74.981 141.384 0 256-114.616 256-256z"
                fill="#d80027"
            />
            <path
                d="m74.98 74.98c-99.974 99.974-99.974 262.065 0 362.04 41.313-41.313 81.046-81.046 181.02-181.02z"
                fill="#0052b4"
            />
        </svg>
    );
}
