import type { SVGProps } from 'react';

export default function EeFlag(props: SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            preserveAspectRatio="xMidYMid slice"
            {...props}>
            <path d="m0 256c0 31.314 5.633 61.31 15.923 89.043l240.077 11.131 240.077-11.13c10.291-27.734 15.923-57.73 15.923-89.044s-5.632-61.31-15.923-89.043l-240.077-11.131-240.077 11.13c-10.29 27.734-15.923 57.73-15.923 89.044z" />
            <path
                d="m256 0c-110.071 0-203.906 69.472-240.077 166.957h480.155c-36.172-97.485-130.007-166.957-240.078-166.957z"
                fill="#0052b4"
            />
            <path
                d="m496.077 345.043h-480.154c36.171 97.484 130.006 166.957 240.077 166.957s203.906-69.473 240.077-166.957z"
                fill="#f0f0f0"
            />
        </svg>
    );
}
