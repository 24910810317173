import type { SVGProps } from 'react';

export default function BeFlag(props: SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            preserveAspectRatio="xMidYMid slice"
            {...props}>
            <path
                d="m345.043 15.923c-27.733-10.29-57.729-15.923-89.043-15.923s-61.31 5.633-89.043 15.923l-22.261 240.077 22.261 240.077c27.733 10.291 57.729 15.923 89.043 15.923s61.31-5.632 89.043-15.923l22.261-240.077z"
                fill="#ffda44"
            />
            <path
                d="m512 256c0-110.07-69.472-203.906-166.957-240.076v480.155c97.485-36.173 166.957-130.007 166.957-240.079z"
                fill="#d80027"
            />
            <path d="m0 256c0 110.072 69.472 203.906 166.957 240.078v-480.154c-97.485 36.17-166.957 130.006-166.957 240.076z" />
        </svg>
    );
}
