import type { SVGProps } from 'react';

export default function GrFlag(props: SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            preserveAspectRatio="xMidYMid slice"
            {...props}>
            <circle cx="256" cy="256" fill="#f0f0f0" r="256" />
            <g fill="#338af3">
                <path d="m256 189.217h247.181c-6.419-23.814-16.175-46.255-28.755-66.783h-218.426z" />
                <path d="m96.643 456.348h318.713c23.363-18.608 43.399-41.21 59.069-66.783h-436.851c15.671 25.572 35.707 48.175 59.069 66.783z" />
                <path d="m89.043 61.939c-20.126 17.331-37.525 37.74-51.469 60.496h51.469z" />
                <path d="m256 256c0-26.076 0-47.899 0-66.783h-100.174v66.783h-66.783v-66.783h-80.224c-5.739 21.294-8.819 43.676-8.819 66.783s3.08 45.489 8.819 66.783h494.363c5.738-21.294 8.818-43.676 8.818-66.783z" />
                <path d="m256 0c-35.549 0-69.404 7.253-100.174 20.348v102.087h100.174c0-23.312 0-43.139 0-66.783h159.357c-43.731-34.828-99.108-55.652-159.357-55.652z" />
            </g>
        </svg>
    );
}
