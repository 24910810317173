'use client';

import type { LinkProps } from 'next/link';
import NextLink from 'next/link';
import { useRouter } from 'next/navigation';
import type { AnchorHTMLAttributes } from 'react';
import { forwardRef } from 'react';

import { FEATURE_FLAGS } from '@/config/featureFlags';
import buildUrl from '@/utils/buildUrl';
import { isExternalLink } from '@/utils/slug/isExternalLink';

export type CustomLinkProps = {
    href?: string | null;
    locale?: string;
    customPrefetch?: 'onMouseEnter';
} & Omit<AnchorHTMLAttributes<HTMLAnchorElement>, 'href'> &
    Omit<LinkProps, 'href'>;

const Link = forwardRef<HTMLAnchorElement, CustomLinkProps>(
    ({ href, locale, customPrefetch, ...props }, ref) => {
        const router = useRouter();

        if (href && isExternalLink(href)) {
            return (
                <a
                    href={href}
                    target="_blank"
                    rel="noopener noreferrer"
                    {...props}
                />
            );
        }

        const url = buildUrl(
            locale,
            href?.split('/').filter(Boolean).join('/'),
        );

        const prefetch = () => router.prefetch(url);

        const prefetchProps = FEATURE_FLAGS.next.prefetch
            ? customPrefetch && {
                  ...(customPrefetch === 'onMouseEnter' && {
                      onMouseEnter: prefetch,
                  }),
              }
            : {
                  prefetch: false,
                  onMouseEnter: prefetch,
              };

        return <NextLink ref={ref} href={url} {...prefetchProps} {...props} />;
    },
);

export default Link;
