import type { SVGProps } from 'react';

export default function PlFlag(props: SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            preserveAspectRatio="xMidYMid slice"
            {...props}>
            <circle cx="256" cy="256" fill="#f0f0f0" r="256" />
            <path
                d="m512 256c0 141.384-114.616 256-256 256s-256-114.616-256-256"
                fill="#d80027"
            />
        </svg>
    );
}
