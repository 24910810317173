import type { SVGProps } from 'react';

export default function LtFlag(props: SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            preserveAspectRatio="xMidYMid slice"
            {...props}>
            <path
                d="m496.077 345.043c10.291-27.732 15.923-57.729 15.923-89.043s-5.632-61.311-15.923-89.043l-240.077-22.261-240.077 22.261c-10.291 27.732-15.923 57.729-15.923 89.043s5.632 61.311 15.923 89.043l240.077 22.261z"
                fill="#6da544"
            />
            <path
                d="m496.077 166.957c-36.171-97.485-130.006-166.957-240.077-166.957s-203.906 69.472-240.077 166.957z"
                fill="#ffda44"
            />
            <path
                d="m256 512c110.071 0 203.906-69.472 240.077-166.957h-480.154c36.171 97.485 130.006 166.957 240.077 166.957z"
                fill="#d80027"
            />
        </svg>
    );
}
