import { graphql } from '@server/gql';

export default graphql(/* GraphQL */ `
    query EntryById($id: String!, $preview: Boolean, $locale: String!) {
        entryCollection(
            locale: $locale
            limit: 1
            where: { sys: { id: $id } }
            preview: $preview
        ) {
            items {
                __typename
                sys {
                    id
                }
                ... on Page {
                    slug
                }
                ... on LandingPage {
                    slug
                }
                ... on EditorialPage {
                    slug
                }
                ... on Campaign {
                    slug
                }
            }
        }
    }
`);
