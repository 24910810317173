import Frame from '@app/_components/Frame/Frame';
import Link from '@components/Link';
import { RichTextLink } from '@components/RichTextLink';
import { Paragraph } from '@components/ui';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import type { Node } from '@contentful/rich-text-types';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { type HTMLAttributes, type ReactNode, useMemo } from 'react';
import type { Links } from 'types/contentfulRichText';

import { getMarketConfigFromLocale } from '@/config/market-configurations';
import { cn } from '@/lib/utils';
import isServer from '@/utils/isServer';

import ClientFriendlyEntry from '../Entry/ClientFriendlyEntry';
import RichTextAsyncLink from './RichTextAsyncLink';

export function parseOptions({
    content,
    market,
    locale,
    Component = ClientFriendlyEntry,
    client = true,
    options,
    onClick,
}: {
    content: {
        links: Links;
    };
    market: string;
    locale?: string;
    Component?: (props?: any) => JSX.Element | null;
    client?: boolean;
    onClick?: () => void;
    options?: {
        renderMark: Record<
            keyof BLOCKS,
            (props: any, children: ReactNode) => ReactNode
        >;
        renderNode: Record<
            keyof BLOCKS,
            (props: any, children: ReactNode) => ReactNode
        >;
    };
}) {
    const marketConfig = getMarketConfigFromLocale(locale);
    const entryMap = new Map();

    /**
     * Here we map all the entries and assets to their respective sys.id and populate the data from "links" object from the Grapql query.
     * By doing this we can use the data to render components or hyperlinks in the rich text.
     * Sometimes we can't query the links data since the query can get too complex. In those cases we use getPageById to fetch the entry data and grab the slug.
     */
    if (content?.links?.entries?.block) {
        content?.links.entries.block.forEach((entry) => {
            if (entry?.sys?.id) entryMap.set(entry.sys.id, entry);
        });
    }
    if (content?.links?.entries?.inline) {
        content?.links.entries?.inline.forEach((entry) => {
            if (entry?.sys?.id) entryMap.set(entry.sys.id, entry);
        });
    }

    if (content?.links?.assets?.block) {
        content?.links.assets?.block.forEach((entry) => {
            if (entry?.sys?.id) entryMap.set(entry.sys.id, entry);
        });
    }

    if (content?.links?.entries?.hyperlink) {
        content?.links.entries.hyperlink.forEach((entry) => {
            if (entry?.sys?.id) entryMap.set(entry.sys.id, entry);
        });
    }

    return {
        renderNode: {
            [INLINES.HYPERLINK]: ({ data }: Node, children: ReactNode) => {
                return (
                    <RichTextLink locale={locale} href={data.uri}>
                        {children}
                    </RichTextLink>
                );
            },
            [INLINES.ENTRY_HYPERLINK]: (
                { data }: Node,
                children: ReactNode,
            ) => {
                const entry = entryMap.get(data.target.sys.id);

                // Check if we got a slug from the links object, if so render the Link with the slug as href.
                if (entry && entry.slug) {
                    return (
                        <Link locale={locale} href={entry.slug}>
                            {children}
                        </Link>
                    );
                }
                if (onClick) {
                    return (
                        <button
                            onClick={onClick}
                            type="button"
                            className="underline">
                            {children}
                        </button>
                    );
                }

                return isServer() ? (
                    <RichTextAsyncLink
                        id={data.target.sys.id}
                        marketConfig={marketConfig}>
                        {children}
                    </RichTextAsyncLink>
                ) : (
                    <span>{children}</span>
                );
            },
            [INLINES.EMBEDDED_ENTRY]: ({ data }: Node) => {
                const entry = entryMap.get(data.target.sys.id);
                if (entry)
                    return (
                        <Component
                            client={client}
                            {...entry}
                            locale={locale}
                            market={market}
                        />
                    );
                return null;
            },
            [BLOCKS.EMBEDDED_ENTRY]: ({ data }: Node) => {
                const entry = entryMap.get(data.target.sys.id);
                if (entry)
                    return (
                        <Component
                            client={client}
                            {...entry}
                            locale={locale}
                            market={market}
                        />
                    );
                return null;
            },
            [BLOCKS.PARAGRAPH]: (node: Node, children: ReactNode) => {
                if (Array.isArray(children)) {
                    const [content] = children;
                    if (Array.isArray(content)) {
                        const [, text] = content;
                        if (text.startsWith('<iframe')) {
                            return <Frame text={text} />;
                        }
                    }
                }
                return <Paragraph>{children}</Paragraph>;
            },
            ...options?.renderNode,
        },
        renderText: (text: string) => {
            if (!text.length) return null;
            return text.split('\n').flatMap((t, i) => [i > 0 && <br />, t]);
        },
    };
}

export default function RichText({
    content,
    market,
    locale,
    client,
    options,
    className,
    onClick,
    ...props
}: Omit<HTMLAttributes<Element>, 'content'> & {
    content: any;
    client?: boolean;
    options?: any;
    className?: string;
    market: string;
    locale: string | undefined;
    onClick?: () => void;
}) {
    const parsedOptions = useMemo(
        () =>
            parseOptions({
                content,
                market,
                locale,
                client,
                options,
                onClick,
            }),
        [client, content, locale, market, onClick, options],
    );

    if (!content) {
        return null;
    }

    const text = documentToReactComponents(content?.json, parsedOptions);

    if (!text) {
        return null;
    }

    return (
        <div
            className={cn(
                !className?.includes('not-prose') && 'prose',
                className,
            )}
            {...props}>
            {text}
        </div>
    );
}
