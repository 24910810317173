import type { SVGProps } from 'react';

export default function SiFlag(props: SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            preserveAspectRatio="xMidYMid slice"
            {...props}>
            <circle cx="256" cy="256" fill="#f0f0f0" r="256" />
            <path
                d="m496.077 166.957h-273.468v-66.783h-133.566v66.783h-73.12c-10.29 27.732-15.923 57.729-15.923 89.043s5.633 61.31 15.923 89.043l240.077 22.261 240.077-22.261c10.29-27.733 15.923-57.729 15.923-89.043s-5.633-61.311-15.923-89.043z"
                fill="#0052b4"
            />
            <path
                d="m256 512c110.071 0 203.906-69.472 240.077-166.957h-480.154c36.171 97.485 130.006 166.957 240.077 166.957z"
                fill="#d80027"
            />
            <path
                d="m89.043 166.957v22.26c0 51.121 66.783 66.784 66.783 66.784s66.783-15.663 66.783-66.784v-22.26l-22.261 22.261-44.522-33.391-44.522 33.391z"
                fill="#f0f0f0"
            />
        </svg>
    );
}
