import { contentful } from '@server/client';
import type {
    EntryByIdQuery,
    EntryByIdQueryVariables,
} from '@server/gql/graphql';
import entryByIdQuery from '@server/queries/contentful/entryById.query';

import { ONCE_PER_EIGHT_HOURS, REVALIDATION_TAGS } from '@/config/cache';

export const getPageById = async ({
    locale,
    preview,
    id,
}: EntryByIdQueryVariables) => {
    try {
        const response = await contentful<
            EntryByIdQuery,
            EntryByIdQueryVariables
        >({
            query: entryByIdQuery,
            variables: {
                id,
                locale,
                preview,
            },
            options: {
                preview: preview ?? false,
                next: {
                    revalidate: ONCE_PER_EIGHT_HOURS,
                    tags: [
                        REVALIDATION_TAGS.page,
                        REVALIDATION_TAGS.landingPage,
                        REVALIDATION_TAGS.editorialPage,
                        REVALIDATION_TAGS.campaign,
                    ],
                },
            },
        });

        const [entry] =
            response.entryCollection?.items.filter(
                (page) =>
                    page?.__typename === 'Page' ||
                    page?.__typename === 'LandingPage' ||
                    page?.__typename === 'EditorialPage' ||
                    page?.__typename === 'Campaign',
            ) ?? [];

        return entry;
    } catch (error: Error | unknown) {
        console.error(error);
        return undefined;
    }
};

export const preload = (variables: EntryByIdQueryVariables) => {
    void getPageById(variables);
};
