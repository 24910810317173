export default function buildUrl(
    locale: string | undefined,
    path?: string | null,
) {
    if (!locale && !path) return `/`;
    let pathname = path;
    if (pathname && pathname.startsWith('/')) pathname = pathname.slice(1);
    if (!pathname && locale) return `/${locale}`;
    if (!locale && pathname) return `/${pathname}`;
    return `/${locale}/${pathname}`;
}
